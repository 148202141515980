import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { BeakerIcon } from '@heroicons/react/20/solid';
import axios, { type AxiosResponse } from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';
import Button from '../controls/Button';
import { classNames } from '../../utilities/styleUtils';
import { getConfig } from '../../config/config-helper';
import ImportColumnLayout from '../layout/ImportColumnLayout';
import TierBadge from '../project/TierBadge';

export default function NewFromGitPath() {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const navigate = useNavigate();
  const { dataopsCatalogApi2Endpoint } = getConfig();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, []);

  // const appName = "dataops-live-project-morph-local";

  const validateUrl = (url: string) => {
    const regex = /^(https?:\/\/)?(www\.)?github\.com\/[A-Za-z0-9_.-]+\/[A-Za-z0-9_.-]+\/?$/;
    return regex.test(url);
  };

  const submitImportRequestAndReturnId = async (url: string): Promise<number | undefined> => {
    try {
      const response: AxiosResponse<any, any> = await axios.post(`${dataopsCatalogApi2Endpoint}/import`, {
        repo_path: url,
        oauth_token: token,
      });
      return response.data.id;
    } catch (error) {
      console.error(error);
      setError('Failed to submit the URL. Please try again.');
      return undefined;
    }
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (validateUrl(url)) {
      setError('');
      // await installGitHubApp();
      const importId = await submitImportRequestAndReturnId(url);
      if (importId !== undefined) {
        navigate(`/new/from-git/import/${importId}`);
      }
      setError('');
    } else {
      setError('Invalid GitHub repository URL.');
    }
  };

  const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setUrl(event.target.value);
    if (error !== '') setError('');
  };

  return (
    <ImportColumnLayout>
      <div>
        <span
          className={classNames(
            'text-dataops-light-green',
            // 'bg-dataops-light-green/10',
            'inline-flex rounded-lg p-3 ring-1 ring-dataops-light-green',
          )}
        >
          <GlobeAltIcon aria-hidden="true" className="h-6 w-6" />
        </span>
      </div>
      <h1 className="text-xl">New Solution from Git repository</h1>
      <div className="flex space-x-1">
        <div className="inline-flex items-center px-1 space-x-1 text-sm text-gray-800 rounded-md ring-1 ring-inset cursor-default ring-gray-500/10">
          <BeakerIcon className="w-4 h-4" /> <span>Private Preview</span>
        </div>
        <TierBadge tier={4} />
      </div>
      <form onSubmit={handleSubmit} className="w-full">
        <div className="flex flex-row gap-2">
          <div className="h-[33px] relative flex-1 rounded-md bg-dataops-supporting-gray/50">
            <GlobeAltIcon
              className="pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
            <input
              ref={inputRef}
              onChange={handleChange}
              placeholder="Paste in a link to a public repo"
              className="h-[33px] w-full border border-gray-200 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-primary-light-blue/20 focus:outline-4 transition-all ease-in-out"
              data-testid="search-input"
              value={url}
            />
          </div>
          <Button
            type="submit"
            className="rounded-md bg-dataops-secondary-dark-blue text-white hover:bg-dataops-secondary-dark-blue/80 hover:text-white"
            data-testid="import-button"
          >
            Import
          </Button>
        </div>
      </form>
      {error !== '' && <p>{error}</p>}
      <Button
        intent={'light'}
        onClick={() => {
          navigate('/new');
        }}
      >
        Back
      </Button>
    </ImportColumnLayout>
  );
}
